import SEO from '@/components/shared/SEO'
import * as styles from '@/styles/pages/contact.module.css'
import { AnimatePresence, motion } from 'framer-motion'
import { useForm } from 'react-hook-form'

interface FormData {
  name: string
  email: string
  phone: string
  message: string
}

function ErrorMessage({
  name,
  error,
  message,
}: {
  name: string
  error?: boolean
  message?: string
}) {
  return (
    <AnimatePresence exitBeforeEnter>
      {error && (
        <motion.span
          key={name}
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          className="text-error-400 caption-sm"
        >
          {message}
        </motion.span>
      )}
    </AnimatePresence>
  )
}

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()
  function onSubmit(data: FormData) {
    console.log(data)
  }

  return (
    <>
      <SEO title="Contact" />
      <div className="flex flex-col py-16 lg:space-x-1 lg:flex-row base-container">
        <div className="flex flex-col w-full space-y-8">
          <h1 className="section-heading">Liên hệ</h1>
          <p className="heading-6 lg:subheading-5">
            Liên hệ trực tiếp qua{' '}
            <a
              className="text-blue-500 hover:text-blue-600"
              href="mailto:contact@healthliteracy.vn"
            >
              contact@healthliteracy.vn
            </a>
          </p>
        </div>
        <form
          className="flex flex-col w-full space-y-6"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={styles.formField}>
            <input
              type="text"
              placeholder="Họ tên *"
              aria-invalid={errors.name ? 'true' : 'false'}
              className={styles.input}
              {...register('name', { required: true })}
            />
            <ErrorMessage
              name="input-name"
              error={errors.name?.type === 'required'}
              message="Vui lòng nhập tên"
            />
          </div>
          <div className={styles.formField}>
            <input
              type="text"
              placeholder="Email *"
              aria-invalid={errors.email ? 'true' : 'false'}
              className={styles.input}
              {...register('email', {
                required: true,
                pattern: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/i,
              })}
            />
            <ErrorMessage
              name="input-email-required"
              error={errors.email?.type === 'required'}
              message="Vui lòng nhập email"
            />
            <ErrorMessage
              name="input-email-pattern"
              error={errors.email?.type === 'pattern'}
              message="Email không hợp lệ"
            />
          </div>
          <div className={styles.formField}>
            <input
              type="text"
              placeholder="Số điện thoại *"
              className={styles.input}
              aria-invalid={errors.phone ? 'true' : 'false'}
              {...register('phone', {
                required: true,
                pattern: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i,
              })}
            />
            <ErrorMessage
              name="input-phone-required"
              error={errors.phone?.type === 'required'}
              message="Vui lòng nhập số điện thoại"
            />
            <ErrorMessage
              name="input-phone-pattern"
              error={errors.phone?.type === 'pattern'}
              message="Số điện thoại không hợp lệ"
            />
          </div>
          <div className={styles.formField}>
            <textarea
              placeholder="Lời nhắn *"
              rows={10}
              className={styles.textarea}
              aria-invalid={errors.message ? 'true' : 'false'}
              {...register('message', { required: true })}
            />
            <ErrorMessage
              name="input-message-required"
              error={errors.message?.type === 'required'}
              message="Vui lòng nhập lời nhắn"
            />
          </div>
          <div>
            <button type="submit" className="primary hover:dark">
              Gửi lời nhắn
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default Contact
